.left-banner, .right-banner {
    width : 200px;
    display: flex;
    justify-content: center;
    margin-left : 5px;
    margin-right: 5px;
  }
  
.banner-item {
  width : 150px;
  height : 600px;
  background-color: gray;
}

.contents-body {
  position: relative;
}

.bottom-banner-item {
  width : 600px;
  height: 150px;
  background-color: gray;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-banner-item > img {
  width : 600px;
  height: 150px;
}
  
.banner-body {
    position: fixed;
    top: 150px;
    background-color: gray;
    width: 150px;
    height: 400px;
}

.b-left {
    margin-left: 20px;
}

.b-right {
    right: 0px;
    margin-right: 20px;
}

.banner-link {
  cursor: pointer;
}