.form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    margin-bottom: 200px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group .required {
    color: red;
}

.input-field {
    width: 65%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input-wrapper .input-field {
    flex-grow: 1;
    margin-right: 10px;
}

.action-button {
    width: 90px;
    padding: 8px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
}

.action-button:hover {
    background-color: #0056b3;
}

.join-info {
    font-size: 0.9em;
    color: #777;
    margin-top: 5px;
    display: block;
}

.warning {
    color: red;
    font-size: 0.9em;
}

.password-group {
    display: flex;
    flex-direction: column;
    gap: 10px; /* 비밀번호와 비밀번호 확인 간의 간격 */
}