body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  height : 110px;
}

.header .middle {
  margin-left : -120px !important;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contents {
  padding: 30px 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.search-target-url {
  width : 300px;
  margin : 0 10px;
}

.about {
  margin-bottom: 180px;
}

.logo-container {
  /* background-image: url('/frontend/public/static/img/about/smblogo.png'); */
  background-size: 70%; 
  background-position: center;
  background-repeat: no-repeat;
  width: 200px; 
  height: 80px;
}

.header .nav-item {
  padding-bottom : 40px !important;
}