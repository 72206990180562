footer {
    position: relative;
    background-color: #2e2e2e;
    padding: 40px 30px;
}
footer * {
    color:#ffff;
    font-size: 1.1rem;
}
footer p {
    line-height: 22px;
    text-align: center;
}
.footer-inner {
    margin: auto;
    align-items: center;
}
.logo-item {
    margin: 15px auto;
    text-align: center;
    width: 350px;
}
.logo-item>img {
    width: 100%;
}
.number-area {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom-call-icon {
    width: 60px;
    margin-left: -25px; 
}
.bottom-call-icon>img {
    width: 100%;
    cursor: pointer;
}
.corp-number {
    font-size: 1.8rem;
    font-weight: 700;
    cursor: pointer;
}
.boundary-line {
    width: 60%;
    height: 2px;
    background-color: #ffffff3b;
    margin: 30px auto;
}
.number-desc {
    margin-top: 7px;
}


@media screen and (max-width: 1050px) {
    .menus, .home-desc-wrap, .menu-desc, .bottom-call-icon {
        display: none;
    }
    .logo {
        min-width: 220px;
        margin: 0;
    }
    .login {
        position: absolute;
        right: 15px;
        min-width: 125px;
    }
    .number-area, .hamburger-btn-wrap {
        display: block;
    }
    footer p {
        text-align: start;
    }
    .logo-item {
        margin: 0;
    }
    .footer-inner {
        width: 100%;
        margin: 0;
    }
    .boundary-line {
        margin: 30px 0;
    }
}